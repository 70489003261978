<template>
  <b-container
    tag="main"
    class="ombre"
  >
    <div class="float-right">
      <I18n />
    </div>

    <div>
      <h2 v-html="$t('journal.title2')"></h2>
      <div class="text-center journal">
        <router-link :to="`/${$t('lang')}/journal`">
          <img
            :src="`${$t('/')}img/home-journal-intro.png`"
            alt=""
          />
          <br />
          <span v-html="$t('journal.read')"></span>
        </router-link>
      </div>
    </div>

    <div v-html="$t('home.intro')"></div>
    <p>
      <b-button
        size="sm"
        variant="outline-secondary"
        href="https://framablog.org/2017/10/09/contributopia-degoogliser-ne-suffit-pas/"
        v-html="$t('txt.more')"
      />
    </p>

    <b-row
      v-for="section in ['services', 'essaimage', 'educ-pop']"
      :key="section"
      tag="section"
    >
      <b-col
        class="text-center"
        md="4"
      >
        <router-link
          :to="`/${$t('lang')}/${section}`"
          aria-hidden="true"
        >
          <img
            :src="`${$t('/')}img/menu-${section}.png`"
            alt=""
          />
        </router-link>
      </b-col>

      <b-col md="8">
        <div v-html="$t(`home.${section}`)"></div>
        <p>
          <b-button
            size="sm"
            variant="outline-secondary"
            :to="`/${$t('lang')}/${section}`"
            v-html="$t('txt.more')"
          />
        </p>
      </b-col>
    </b-row>

    <Credits />
  </b-container>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';
import Credits from '../components/Credits.vue';

export default {
  components: {
    I18n,
    Credits,
  },
};
</script>
