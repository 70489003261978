<template>
  <div>
    <h1 v-html="$t('journal.title')"></h1>

    <polyptyque />

    <b-container
      tag="main"
      class="ombre"
    >
      <div id="postImage">
        <b-button
          :to="`/${$t('lang')}/`"
          variant="outline-secondary"
        >
          <i class="fas fa-arrow-left fa-lg"></i>
          <span v-html="$t('txt.backHome')"></span>
        </b-button>

        <div class="float-right">
          <I18n />
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12"
          v-html="$t('journal.summary.intro')"
        ></div>
        <h2
          class="col-sm-12"
          v-html="$t('journal.summary.title')"
        ></h2>
        <div
          v-for="index in 4"
          :key="index"
          class="col-sm-12 col-md-6 col-lg-3"
        >
          <ul class="list-group">
            <li
              v-for="key in summary[index - 1]"
              :key="key"
              class="list-group-item"
            >
              <time
                class="float-right text-muted small"
                :datetime="new Date(blogDate($t(`summary.${key}.fr`)))
                  .toISOString()
                  .substring(0, 10)"
                v-html="date(blogDate($t(`summary.${key}.fr`)))"
              ></time>
              <b v-html="$t(`journal.summary.polyptyque.${key}`)"></b>
              <br />
              <span>
                <a
                  :href="$t(`summary.${key}.fr`)"
                  v-html="$i18n.messages.translations.fr"
                ></a>
                <span v-if="$te(`summary.${key}.en`)">
                  –
                  <a
                    :href="$t(`summary.${key}.en`)"
                    v-html="$i18n.messages.translations.en"
                  ></a>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <Credits />
    </b-container>
  </div>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';
import Credits from '../components/Credits.vue';
import Polyptyque from './journal/Polyptyque.vue';

export default {
  components: {
    I18n,
    Credits,
    Polyptyque,
  },

  data() {
    const summary = [];
    const keys = Object.keys(this.$t('summary'));

    for (let i = 0; i < 4; i += 1) {
      summary[i] = keys.slice(0 + i * 4, 4 + i * 4);
    }

    return {
      summary,
    };
  },
  methods: {
    blogDate(link) {
      const date = link.match(/.*(2019\/[0-9]{2}\/[0-9]{2}).*/)[1];
      return date;
    },

    date(date) {
      return new Date(date).toLocaleDateString(this.$t('lang'), {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  },
};
</script>

<style lang="scss">
#journal {
  background: var(--f-d9) url('../../public/img/journal/topo.png') repeat;

  header { display: none; }

  #app h1 {
    text-align: center;
    font: 50px 'IM Fell English', Helvetica, Arial, sans-serif;
    text-shadow: 2px 1px 1px var(--f-d9);
    font-style: italic;
    font-weight: 400;
    color: var(--f-d1);
  }

  .container.ombre {
    background: var(--f-o1) url('../../public/img/journal/croquisb.jpg') repeat-y;
    margin-top: 10px;
  }

  #postImage {
    width: calc(100% + 60px);
    height: 500px;
    background-position: top;
    background-size: cover;
    background-image: url('../../public/img/journal/sketch01_carnet-intro_by_David_Revoy.jpg');
    margin: -20px 0px 20px -30px;
    padding: 70px 30px;
  }

  .list-group li {
    border: none;
    border-radius: 0;
    border-left: 4px solid var(--f-m7);
    background: var(--f-o2);
    margin-bottom: 10px;

    a {
      color: var(--f-m7);
    }
  }

  footer {
    background: none;
  }
}
</style>
