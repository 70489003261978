<template>
  <div
    class="credits signature text-right small"
    v-html="$t('credits', credits)"
  ></div>
</template>

<script>
export default {
  data() {
    return {
      credits: {
        start: this.date('meta.start'),
        edit: this.date('config.date'),
        author: '<a href="http://www.davidrevoy.com">David Revoy</a>',
      },
    };
  },

  methods: {
    date(date) {
      return new Date(this.$t(date)).toLocaleDateString(this.$t('lang'), {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
};
</script>

<style>
.credits {
  margin-top: 20px;
  margin-bottom: 0;
}

.credits p {
  margin-bottom: 0;
}
</style>
