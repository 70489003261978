<template>
  <div
    id="footer"
    class="hidden-print"
  >
    <b-container
      v-if="!/home|journal/.test($route.meta.id)"
      class="py-5 px-0"
      tag="footer"
    >
      <b-row class="menu">
        <h2
          class="col-12"
          v-html="$t('footer.explore.title')"
        ></h2>
        <b-col
          class="text-center mb-2"
          md
        >
          <router-link :to="`/${$t('lang')}/`">
            <b-img
              fluid
              alt=""
              :src="`${$t('/')}img/menu-home.png`"
            />
            <br />
            <b-button
              variant="outline-secondary mt-3 mb-5"
              tag="span"
              v-html="$t('home.title')"
            />
          </router-link>
        </b-col>
        <b-col
          class="text-center mb-2"
          md
        >
          <router-link :to="`/${$t('lang')}/journal`">
            <b-img
              fluid
              alt=""
              :src="`${$t('/')}img/menu-journal.png`"
            />
            <br />
            <b-button
              variant="outline-secondary mt-3 mb-5"
              tag="span"
              v-html="$t('journal.title2')"
            />
          </router-link>
        </b-col>
        <b-col
          v-for="section in ['services', 'essaimage', 'educ-pop']"
          v-show="$route.meta.id !== section"
          :key="section"
          class="text-center mb-2"
          md
        >
          <router-link :to="`/${$t('lang')}/${section}`">
            <b-img
              fluid
              alt=""
              :src="`${$t('/')}img/menu-${section}.png`"
            />
            <br />
            <b-button
              variant="outline-secondary mt-3 mb-5"
              tag="span"
              v-html="$t(`${section}.title`)"
            />
          </router-link>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-archive"
      :title="$t('modal.title')"
      v-model="modalOpened"
      :header-close-label="$t('txt.close')"
      :cancel-title-html="$t('txt.close')"
      cancel-variant=" d-none"
    >
      <div v-html="$t('modal.archive')"></div>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      modalOpened: false
    }
  },
  mounted() {
    this.modalOpened = true;
  },
  watch: {
    modalOpened(newValue) {
      if (newValue === false) {
        document.querySelectorAll('#modal-archive___BV_modal_outer_').forEach(elem => elem.remove());
      }
    }
  }
};
</script>

<style lang="scss">
#footer {
  background: var(--f-g3);

  h2 {
    font-family: 'IM Fell English', Helvetica, Arial, sans-serif;
  }

  img {
    width: 80%;
    margin: 0 auto;
    box-shadow: none;
  }
}
</style>
