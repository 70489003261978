<template>
  <b-container
    tag="main"
    class="ombre"
  >
    <b-button
      :to="`/${$t('lang')}/`"
      variant="outline-secondary"
    >
      <i class="fas fa-arrow-left fa-lg"></i>
      <span v-html="$t('txt.backHome')"></span>
    </b-button>

    <div class="float-right">
      <I18n />
    </div>

    <div class="row mt-4">
      <div
        grid
        class="col-md-8"
      >
        <h1 v-html="$t('educ-pop.title')"></h1>
        <div v-html="$t('educ-pop.intro')"></div>
      </div>
    </div>

    <section
      v-for="key in Object.keys($t('educ-pop.sections'))"
      :key="key"
      class="row"
    >
      <div class="col-md-4 text-center">
        <img
          :src="`${$t('/')}img/educ-pop-${key}.jpg`"
          alt=""
        />
      </div>
      <div class="col-md-8">
        <div v-html="$t(`educ-pop.sections.${key}`)"></div>
      </div>
    </section>

    <Credits />
  </b-container>
</template>

<script>
import I18n from '../../commons/components/I18n.vue';
import Credits from '../components/Credits.vue';

export default {
  components: {
    I18n,
    Credits,
  },
};
</script>
